<template>
  <b-card>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <b-col cols="6">
          <b-form-group
            :label="$t('variable.name')"
            :class="{ error: v$.variable.name.$errors.length }"
          >
            <b-form-input readonly v-model="variable.name"/>
            <div
              class="input-errors"
              v-for="error of v$.variable.name.$errors"
              :key="error.$uid"
            >
              <small class="text-danger">{{ error.$message }}</small>
            </div>
          </b-form-group>

          <b-form-group :label="$t('variable.value')">
            <b-form-input v-model="variable.value"/>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import {required} from "@vuelidate/validators";

export default {
  components: {},
  props: {
    variableObject: {Object, required: true},
    action: {type: String, default: null},
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      variable: {
        name: {required},
        value: {required},
      },
    };
  },
  created() {
  },
  data() {
    return {
      variable: this.variableObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          name: this.variable.name,
          value: this.variable.value,
        };
        if (this.action) {
          this.action === "add"
            ? this.create(
              this.$Variables,
              body,
              this.$t("variable.created"),
              null,
              this.success,
              this.error
            )
            : this.update(
              this.$Variables,
              this.variable.name,
              body,
              this.$t("variable.updated"),
              null,
              null,
              this.error
            );
          this.$emit("clearAction");
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/variables`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
